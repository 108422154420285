<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Tiger_Q1/Menu'
import Alert from '@/components/Alert'

export default {
	name: "TigerQ1_home",
	data() {
		return {
			isActiveAlert:false
		};
	},
	setup () {
		useMeta(
		{ 
			title: '虎牌啤酒熱情開燥整年龍吼啦',
			description: '2024/1/24-2/29指定通路，買虎牌全系列商品，登錄發票抽新加坡雙人套裝行程' 
		})		
	},
	components: {
		Menu,
		Alert

	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		checkBrowser(){
			// console.log("checkBrowser")
			let vm=this
			var useragent = navigator.userAgent;
			var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(Instagram|Line|FBAN|FBAV)']
			var regex = new RegExp(`(${rules.join('|')})`, 'ig');
			if(Boolean(useragent.match(regex))){
				vm.isActiveAlert=true
			}else{
				location.href="https://heineken-event.webgene.com.tw/auth/redirect/tiger-q1"
			}
		},
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
